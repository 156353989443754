import { combineReducers } from "@reduxjs/toolkit"
import { authReducer } from "./slices/Auth"
import { productReducer } from "./slices/Product";
import { apiCall } from "./apiCall";
import { modalReducer } from "./slices/Modal";

const rootReducer = combineReducers({
  Auth: authReducer,
  Product: productReducer,
  Modal: modalReducer,
  [apiCall.reducerPath]: apiCall.reducer,
})

export default rootReducer
