import { Navigate } from "react-router-dom";
import { Suspense, lazy } from "react";
import Authmiddleware from "./route";
import { user_roles } from "../config/user.config";
import ProtectedLayout from "../components/templates/ProtectedLayout";
import PublicLayout from "../components/templates/PublicLayout";
import Spinner from "../components/atoms/Spinner";

// eslint-disable-next-line react/display-name
const Loadable = Component => props =>
  (
    <Suspense fallback={
      <Spinner isLoading={true}/>
    }>
      <Component {...props} />
    </Suspense>
  )

// ADMIN PAGE START
const AdminDashboardPage = Loadable(lazy(() => import("../pages/admin/Dashboard/index")))
const AdminViewMerchantDashboardPage = Loadable(lazy(() => import("../pages/admin/ViewMerchantDashboard/index")))
const AdminProductOrder = Loadable(lazy(() => import("../pages/admin/ProductOrder/index")))
const AdminReturnAndRefundOrderPage = Loadable(lazy(() => import("../pages/admin/ReturnAndRefundOrder/index")))
const AdminCancellationAndRefundOrderPage = Loadable(lazy(() => import("../pages/admin/CancellationAndRefundOrder/index")))
const AdmintProductManagementPage = Loadable(lazy(() => import("../pages/admin/ProductManagement/index")))
const AdminServiceOrder = Loadable(lazy(() => import("../pages/admin/ServiceOrder/index")))
const AdminArticleManagement = Loadable(lazy(() => import("../pages/admin/ArticleManagement/ArticleManagementList")))
// const AdmintForReturnManagementPage = Loadable(lazy(() => import("../pages/admin/ForReturnManagement/index")))
// const AdmintForRefundManagementPage = Loadable(lazy(() => import("../pages/admin/ForRefundManagement/index")))

const AdminReturnOrderPage = Loadable(lazy(() => import("../pages/admin/ReturnOrder/index")))

const AdmintServiceManagementPage = Loadable(lazy(() => import("../pages/admin/ServiceManagement/index")))
const AdmintPetCategoryManagementPage = Loadable(lazy(() => import("../pages/admin/PetCategoryManagement/index")))
const AdmintPetSubCategoryManagementPage = Loadable(lazy(() => import("../pages/admin/PetCategoryManagement/PetSubCategoryManagement/index")))
const AdmintUserManagementPage = Loadable(lazy(() => import("../pages/admin/UserManagement/index")))
const AdminProfilePage = Loadable(lazy(() => import("../pages/admin/Profile")))
const AdminVocherManagementPage = Loadable(lazy(() => import("../pages/admin/VocherManagement")))

// ADMIN PAGE END

// MERCHANT PAGE START
const MerchantDashboard = Loadable(lazy(() => import("../pages/merchant/Dashboard/index")))

const MerchantReports = Loadable(lazy(() => import("../pages/merchant/Reports/index")))

const MerchantProductManagementList = Loadable(lazy(() => import("../pages/merchant/ProductManagement/ProductManagementList")))

const MerchantProductOrderList = Loadable(lazy(() => import("../pages/merchant/ProductOrder/ProductOderList")))
const MerchantServiceOrderList = Loadable(lazy(() => import("../pages/merchant/ServiceOrder/ServiceOrderList")))

const MerchantReturnOrderPage = Loadable(lazy(() => import("../pages/merchant/ReturnOrder/index")))
const MerchantReturnAndRefundOrderPage = Loadable(lazy(() => import("../pages/merchant/ReturnAndRefundOrder/index")))
const MerchantCancellationAndRefundOrderPage = Loadable(lazy(() => import("../pages/merchant/CancellationAndRefundOrder/index")))

const MerchantServiceManagementList = Loadable(lazy(() => import("../pages/merchant/ServiceManagement/ServiceManagementList")))

const MerchantBookingManagementList = Loadable(lazy(() => import("../pages/merchant/BookingManagement/BookingManagementList")))
const MerchantBookingManagementCalendarView = Loadable(lazy(() => import("../pages/merchant/BookingManagement/CalendarView")))

const MerchantProfile = Loadable(lazy(() => import("../pages/merchant/Profile")));

// MERCHANT PAGE END

// MARGKETING PAGE START
const MarketingDashboardPage = Loadable(lazy(() => import("../pages/admin/Dashboard/index")))
const MarketingViewMerchantDashboardPage = Loadable(lazy(() => import("../pages/admin/ViewMerchantDashboard/index")))
const MarketingProductOrder = Loadable(lazy(() => import("../pages/admin/ProductOrder/index")))
const MarketingReturnAndRefundOrderPage = Loadable(lazy(() => import("../pages/admin/ReturnAndRefundOrder/index")))
const MarketingCancellationAndRefundOrderPage = Loadable(lazy(() => import("../pages/admin/CancellationAndRefundOrder/index")))
const MarketingProductManagementPage = Loadable(lazy(() => import("../pages/admin/ProductManagement/index")))

// MARGKETING PAGE END

const MerchantCompleteProfilePage = Loadable(lazy(() => import("../pages/MerchantCompleteProfile/index")));
const LoginPage = Loadable(lazy(() => import("../pages/Login/index")))
const SignupPage = Loadable(lazy(() => import("../pages/Signup/index")))
const VerifyAccountOtpPage = Loadable(lazy(() => import("../pages/VerifyAccountOtp/index")))
const UnauthorizedPage = Loadable(lazy(() => import("../pages/Unauthorized/index")))
const Pages404 = Loadable(lazy(() => import("../pages/Pages404")))

const authProtectedRoutes = [
  {
    path: "admin",
    element: (
      <Authmiddleware role={user_roles["ADMIN"]}>
        <ProtectedLayout />
      </Authmiddleware>
    ),
    children: [
      {
        path: "dashboard",
        children: [
          {
            path: "",
            element: <AdminDashboardPage />,
          },
          {
            path: "merchant/:merchantId",
            element: <AdminViewMerchantDashboardPage />,
          },
        ],
      },
      {
        path: "product-order",
        children: [
          {
            path: "list",
            element: <AdminProductOrder />,
          },
        ],
      },
      {
        path: "service-order",
        children: [
          {
            path: "list",
            element: <AdminServiceOrder />,
          },
        ],
      },
      {
        path: "return-order",
        children: [
          {
            path: "list",
            element: <AdminReturnOrderPage />,
          },
        ],
      },
      {
        path: "return-and-refund-order",
        children: [
          {
            path: "list",
            element: <AdminReturnAndRefundOrderPage />,
          },
        ],
      },
      {
        path: "cancellation-and-refund-order",
        children: [
          {
            path: "list",
            element: <AdminCancellationAndRefundOrderPage />,
          },
        ],
      },
      {
        path: "product-management",
        children: [
          {
            path: "list",
            element: <AdmintProductManagementPage />,
          },
        ],
      },
      {
        path: "service-management",
        children: [
          {
            path: "list",
            element: <AdmintServiceManagementPage />,
          },
        ],
      },
      {
        path: "article-management",
        children: [
          {
            path: "list",
            element: <AdminArticleManagement />,
          },
        ],
      },
      {
        path: "pet-category",
        children: [
          {
            path: "list",
            element: <AdmintPetCategoryManagementPage />,
          },
          {
            path: ":petCategoryId/pet-sub-category",
            element: <AdmintPetSubCategoryManagementPage />,
          },
        ],
      },
      {
        path: "users-management",
        children: [
          {
            path: "list",
            element: <AdmintUserManagementPage />,
          },
        ],
      },
      {
        path: "profile",
        element: <AdminProfilePage />
      },
      {
        path: "voucher-management",
        element: <AdminVocherManagementPage />
      },
    ],
  },
  {
    path: "merchant",
    element: (
      <Authmiddleware role={user_roles["MERCHANT"]}>
        <ProtectedLayout />
      </Authmiddleware>
    ),
    children: [
      {
        path: "dashboard",
        element: <MerchantDashboard />,
      },
      {
        path: "product-management",
        children: [
          {
            path: "list",
            element: <MerchantProductManagementList />,
          },
        ],
      },
      {
        path: "product-order",
        children: [
          {
            path: "list",
            element: <MerchantProductOrderList />,
          },
        ],
      },
      {
        path: "service-order",
        children: [
          {
            path: "list",
            element: <MerchantServiceOrderList />,
          },
        ],
      },
      {
        path: "return-order",
        children: [
          {
            path: "list",
            element: <MerchantReturnOrderPage />,
          },
        ],
      },
      {
        path: "return-and-refund-order",
        children: [
          {
            path: "list",
            element: <MerchantReturnAndRefundOrderPage />,
          },
        ],
      },
      {
        path: "cancellation-and-refund-order",
        children: [
          {
            path: "list",
            element: <MerchantCancellationAndRefundOrderPage />,
          },
        ],
      },
      {
        path: "reports",
        element: <MerchantReports />,
      },
      {
        path: "service-management",
        children: [
          {
            path: "list",
            element: <MerchantServiceManagementList />,
          },
        ],
      },
      {
        path: "booking-management",
        children: [
          {
            path: "list",
            element: <MerchantBookingManagementList />,
          },
          {
            path: "calendar-view",
            element: <MerchantBookingManagementCalendarView />,
          },
        ],
      },
      {
        path: "profile",
        element: <MerchantProfile />
      },
    ],
  },
  {
    path: "marketing",
    element: (
      <Authmiddleware role={user_roles["MARKETING"]}>
        <ProtectedLayout />
      </Authmiddleware>
    ),
    children: [
      {
        path: "dashboard",
        children: [
          {
            path: "",
            element: <MarketingDashboardPage />,
          },
          {
            path: "merchant/:merchantId",
            element: <MarketingViewMerchantDashboardPage />,
          },
        ],
      },
      {
        path: "product-order",
        children: [
          {
            path: "list",
            element: <MarketingProductOrder />,
          },
        ],
      },
      {
        path: "return-and-refund-order",
        children: [
          {
            path: "list",
            element: <MarketingReturnAndRefundOrderPage />,
          },
        ],
      },
      {
        path: "cancellation-and-refund-order",
        children: [
          {
            path: "list",
            element: <MarketingCancellationAndRefundOrderPage />,
          },
        ],
      },
      {
        path: "product-management",
        children: [
          {
            path: "list",
            element: <MarketingProductManagementPage />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    children: [
      {
        path: "", // equivalent to 👉 / to fix react-router bug
        element: <Navigate to="/login" />,
      },
    ],
  },
];

const publicRoutes = [
  {
    path: "login",
    element: (
      <PublicLayout />
    ),
    children: [
      {
        path: "",
        element: <LoginPage />,
      },
    ],
  },
  {
    path: "signup",
    element: (
      <PublicLayout />
    ),
    children: [
      {
        path: "",
        element: <SignupPage />,
      },
    ],
  },
  {
    path: "verify-otp",
    element: (
      <PublicLayout />
    ),
    children: [
      {
        path: "",
        element: <VerifyAccountOtpPage />,
      },
    ],
  },
  {
    path: "merchant-complete-profile",
    element: (
      <PublicLayout />
    ),
    children: [
      {
        path: "",
        element: <MerchantCompleteProfilePage />,
      },
    ],
  },
  {
    path: "*",
    element: (
      <PublicLayout />
    ),
    children: [
      {
        path: "unauthorized",
        element: <UnauthorizedPage />,
      },
      {
        path: "*",
        element: <Pages404 />,
      }
    ],
  },
];

export { authProtectedRoutes, publicRoutes };