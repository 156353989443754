import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { doSetResetModal } from '../../store/slices/Modal';
import styled from 'styled-components';
import Button from '../atoms/Button';

const ErrorModal = ({ isOpen, toggle, message }) => {
  const modal = useSelector((state) => state.Modal);

  const dispatch = useDispatch()

  const handleOk = () => {
    dispatch(doSetResetModal());
  };

  const handleCancel = () => {
    dispatch(doSetResetModal());
  };

  return (
    <StyledModal isOpen={modal.isShowError} toggle={handleOk} centered={true}>
      <ModalHeader toggle={handleOk}>{`${modal.title || ""}`}</ModalHeader>
      <ModalBody>
        <p>{modal.description}</p>
      </ModalBody>
      <ModalFooter>
        <StyledButton onClick={handleCancel} color="light">
          Ok
        </StyledButton>
      </ModalFooter>
    </StyledModal>
  );
};

export default ErrorModal;

const StyledButton = styled(Button)`
  width: 64px;
  height: 30px;
  border-radius: 5px;
  background-color: #D9D9D9;
  border: #D9D9D9;
  color: #000;
`

const StyledModal = styled(Modal)`
  .modal-header {
    padding: 20px;
    border-bottom: 1px solid #2E2E2E;
  }
  .modal-body {
    padding: 35px 30px;
  }
  .modal-footer {
    padding: 10px 30px;
  }
`