import { useDispatch } from "react-redux";
import { doLogout } from "../store/slices/Auth";
import { useNavigate } from "react-router-dom";

const useSignOut = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return () => {
    dispatch(doLogout())
    navigate("/login")
  };
};

export { useSignOut };