import React from "react";

import SidebarContent from "./SidebarContent";

// import { Link } from "react-router-dom";
// import { useSelector } from "react-redux";
// import capitalizeFirstLetter from "../../../helpers/capitalizeFirstLetter";
import unleashLogo from "../../../assets/images/unleash_logo.png"
import styled from "styled-components";

// import logo from "../../../assets/images/logo.svg";
// import logoLightPng from "../../../assets/images/logo-light.png";
// import logoLightSvg from "../../../assets/images/logo-light.svg";
// import logoDark from "../../../assets/images/logo-dark.png";

const Sidebar = props => {
  // const role = useSelector((state) => state.Auth.role);

  return (
    <React.Fragment>
      <StyledVerticalMenu className="vertical-menu">
        <div className="navbar-brand-box" style={{ marginTop: 20 }}>
           <span className="logo-sm">
              <img src={unleashLogo} alt=""  />
            </span> 
            {/* <span style={{ fontWeight: 500, fontSize: 23 }}> {unleashLogo} </span> */}
            {/* <span className="logo-sm">
              <img src={logo} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logoDark} alt="" height="17" />
            </span> */}

          {/* <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={logoLightSvg} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logoLightPng} alt="" height="19" />
            </span>
          </Link> */}
        </div>
        <div data-simplebar className="h-100">
          <SidebarContent />
        </div>
        <div className="sidebar-background"></div>
      </StyledVerticalMenu>
    </React.Fragment>
  );
};


export default Sidebar

const StyledVerticalMenu = styled.div`
  background-color: #151720;
`