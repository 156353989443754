import React from 'react'

const ProfilePlainIcon = (props) => {
  return (
    <div {...props}>
      <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.23779 16.5C2.5632 14.2892 5.46807 12.7762 10.0001 12.7762C14.5321 12.7762 17.4369 14.2892 18.7623 16.5M13.6001 5.1C13.6001 7.08823 11.9883 8.7 10.0001 8.7C8.01184 8.7 6.40007 7.08823 6.40007 5.1C6.40007 3.11177 8.01184 1.5 10.0001 1.5C11.9883 1.5 13.6001 3.11177 13.6001 5.1Z" stroke="black" strokeWidth="2" strokeLinecap="round"/>
      </svg>
    </div>
  )
}

export default ProfilePlainIcon