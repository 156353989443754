import { createSlice } from "@reduxjs/toolkit"
// import { doCreateProductByMerchant, doDeleteProductByMerchant, doGetAllProductsByMerchant, doUpdateProductByMerchant  } from "../actions/Product.action";

const initialState = {
  isLoading: false,
  data: null,
  specificData: null,
  isSuccess: false,
  isError: false,
  message: null
}

const productSlice = createSlice({
  name: "Product",
  initialState,
  reducers: {
    // doGetProductsByMerchant: (state, { payload }) => {
    //   const findProduct = state.data.find(p => p.id === payload.id);
    //   state.specificData = findProduct
     
    //   return state
    // },
    // doResetProductState: (state) => {
    //   state.isLoading = false;
    //   state.isSuccess = false;
    //   state.isError = false;
    //   state.message = null;
    // }
  },
  extraReducers: (builder) => {
    // builder
    //   .addCase(doGetAllProductsByMerchant.pending, (state) => {
    //     state.isLoading = true
    //   })
    //   .addCase(doGetAllProductsByMerchant.fulfilled, (state, action) => {
    //     state.data = action.payload.list
    //     state.isSuccess = false
    //     state.isLoading = false
    //   })
    //   .addCase(doGetAllProductsByMerchant.rejected, (state, action) => {
    //   })

    //   .addCase(doCreateProductByMerchant.pending, (state) => {
    //     state.isLoading = true
    //   })
    //   .addCase(doCreateProductByMerchant.fulfilled, (state, action) => {
    //     state.message = "Successfully created product!"
    //     state.isSuccess = true
    //   })
    //   .addCase(doCreateProductByMerchant.rejected, (state, action) => {
    //     state.message = action.error.message
    //     state.isError = true
    //   })

    //   .addCase(doUpdateProductByMerchant.pending, (state) => {
    //     state.isLoading = true
    //   })
    //   .addCase(doUpdateProductByMerchant.fulfilled, (state, action) => {
    //     state.message = "Successfully updated product!"
    //     state.isSuccess = true
    //   })
    //   .addCase(doUpdateProductByMerchant.rejected, (state, action) => {
    //     state.message = action.error.message
    //     state.isError = true
    //   })

    //   .addCase(doDeleteProductByMerchant.pending, (state) => {
    //     state.isLoading = true
    //   })
    //   .addCase(doDeleteProductByMerchant.fulfilled, (state, action) => {
    //     state.message = action.payload.message
    //     state.isSuccess = true
    //   })
    //   .addCase(doDeleteProductByMerchant.rejected, (state, action) => {
    //     state.message = action.error.message
    //     state.isError = true
    //   })
  },
})

export const { actions: productActions, reducer: productReducer } = productSlice

export const { doGetProductsByMerchant, doResetProductState} = productActions

export default productSlice;
